import { useState } from "react";
import Hr from "./hr";
import ArticleControls from "./articles-controls";
import ArticleCard from "./article-card";
import { DateUtils } from "../scripts/utils";
import styles from "./articles.module.css";

const NO_DISPLAY_TAG = "no carousel"; // This tag is specified on articles that shouldn't be displayed in the carousel

const setMatchingArticlesByEntity = (articles, entity) => {
  let _article;
  let _thisArticle;
  const _matchingArticles = [];
  const _nonmatchingArticles = [];

  if (null !== entity) {
    // There is a valid entity; check for matching articles and set badges accordingly
    for (_article in articles) {
      _thisArticle = { [_article]: articles[_article] };      
      if ((articles[_article].tags.indexOf(entity) > -1) && (articles[_article].tags.indexOf(NO_DISPLAY_TAG) === -1)) {
        _matchingArticles.push(_thisArticle);
      } else {
        _nonmatchingArticles.push(_thisArticle);
      }
    }   
  } else {
    // There is no valid entity; don't check for matching articles and set badges accordingly
    for (_article in articles) {
      if(articles[_article].tags.indexOf(NO_DISPLAY_TAG) === -1) {
        _thisArticle = { [_article]: articles[_article] };
        _nonmatchingArticles.push(_thisArticle);
      }
    }
  }

  return [_matchingArticles, _nonmatchingArticles];
}

const setArticleCategories = (articles, entity, strings) => {
  let _thisArticle;
  let _badges = [];
  let _diffDays;

  const _categories = { // Category names are the same as `articlesNav` values
    all: {},
    must: {}
  }
  const [_matchingArticles, _nonmatchingArticles] = setMatchingArticlesByEntity(articles, entity);

  // If there are matching articles, sort by date
  if (0 < _matchingArticles.length) {
    _matchingArticles.sort((a, b) => {
      return new Date(a[Object.keys(a)[0]].date.replace(/\|/g, ":")) - new Date(b[Object.keys(b)[0]].date.replace(/\|/g, ":"));
    }).reverse();
  }  

  // Sort nonmatching articles by date also
  if (0 < _nonmatchingArticles.length) {
    _nonmatchingArticles.sort((a, b) => {
      return new Date(a[Object.keys(a)[0]].date.replace(/\|/g, ":")) - new Date(b[Object.keys(b)[0]].date.replace(/\|/g, ":"));
    }).reverse();
  }  

  // If there are matching articles, add them to `all` category first
  if (0 < _matchingArticles.length) {
    let _m;
    for (_m = 0; _m < _matchingArticles.length; _m++) {
      _categories["all"][Object.keys(_matchingArticles[_m])[0]] = articles[Object.keys(_matchingArticles[_m])[0]];
    }
  }  

  // Add remaining articles to `all` category
  if (0 < _nonmatchingArticles.length) {
    let _n;
    for (_n = 0; _n < _nonmatchingArticles.length; _n++) {
      _categories["all"][Object.keys(_nonmatchingArticles[_n])[0]] = articles[Object.keys(_nonmatchingArticles[_n])[0]];
    }
  }

  // Iterate over `all` articles to generate other article categories and badges
  for (_thisArticle in _categories["all"]) {
    if ((_categories["all"][_thisArticle].tags.indexOf(entity) > -1) || (_categories["all"][_thisArticle].tags.indexOf("must read") > -1)) {
      _badges.push(strings.articleBadges.must["label"]);
      _categories["must"][_thisArticle] = _categories["all"][_thisArticle];
    } else {
      _diffDays = DateUtils.getDaysDiffFromNow(_categories["all"][_thisArticle].date);
      if (_diffDays <= 3) {
        _badges.push(strings.articleBadges.new["label"]);
      } else {
        _badges.push(strings.articleBadges.noBadge["label"]);
      }
    }
  }
  
  return [_categories, _badges]
}

const ids = {
  "area": { "id": "articlesArea" }
}

const Articles = ({ articles, entity, strings }) => {  
  const [selectedNav, setSelectedNav] = useState(strings.articlesNav["nav1"]["label"]);
  let _categories;
  let _badges;

  const articlesNavButtonClickHandler = (e) => {
    e.stopPropagation();

    // Get the nav option that was clicked using 'data-nav-key' attribute
    let _navKey = e.currentTarget.attributes.getNamedItem("data-nav-key").value;
    
    setSelectedNav(_navKey);
  }  
  
  [_categories, _badges] = setArticleCategories(articles, entity, strings);

  return(
    <div className = { styles.articlesContainer }>
      <Hr></Hr>
      <ArticleControls selectedNav = { selectedNav } handlers = {{ click: articlesNavButtonClickHandler }} strings = { strings }></ArticleControls>
      <div id = { ids.area.id } className = { styles.articlesGrid }>
        {
          Object.keys(_categories[selectedNav]).map((key, index) => {
            return(
              <ArticleCard key = { index } index = { index } article = { articles[key] } badges = { _badges } selectedNav = { selectedNav } strings = { strings }></ArticleCard>
            )
          })
        }
      </div>
    </div>
  )
}

export default Articles