import { Fragment, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpdatePaymentForm from "./update-payment-form";
import SettingsPlanPageButtons from "./settings-plan-page-buttons";
import CancelConfirmationModal from "./cancel-confirmation-modal";
import Coupon from "./coupon";
import { DateUtils, MathUtils, StringUtils, WebUtils } from "../scripts/utils";
import services from "../scripts/services";
import styles from "./settings-pages.module.css";
import pStyles from "./signup.module.css";

const SettingsPlanPage = ({ user, setJustUpgraded, strings }) => {
    const plans = strings.formContent.plans;
    const [stripePromise, setStripePromise] = useState(null);
    const [option, setOption] = useState(null);
    const [planData, setPlanData] = useState(null);
    const [planStrings, setPlanStrings] = useState({ "title": null, "details": null });
    const [clientSecret, setClientSecret] = useState(null);
    const [statusMessage, setStatusMessage] = useState(null);
    const [isVisibleButtonOptions, setIsVisibleButtonOptions] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isUpgrading, setIsUpgrading] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
    const [hasMadeUpdateOrUpgrade, setHasMadeUpdateOrUpgrade] = useState(false);
    const [isCouponValid, setIsCouponValid] = useState(null);
    const [coupon, setCoupon] = useState("none");
    const [couponStatusMessage, setCouponStatusMessage] = useState("");    
    const [priceID, setPriceID] = useState("")
    const [amount, setAmount] = useState(null);

    const strContent = {
        goodCancel: StringUtils.capitalizeSentences(strings.formContent.notices.goodCancel),
        goodReactivate: StringUtils.capitalizeSentences(strings.formContent.notices.goodReactivate),
        submitPymtLbl: StringUtils.capitalizeWords(strings.formContent.buttons.submitPaymentLabel)
    }

    const toggleModal = () => {
        setIsModalVisible((state) => !state);
    }

    const generatePlanTitle = (plan) => {
        let _planName = StringUtils.capitalizeWords(plans[plan].core.label);
        let _title = StringUtils.capitalizeSentences(plans.all.current.label);
        _title = _title.replace("$1", _planName);
        return _title
    }

    const generatePlanDetails = (plan, data) => {
        /**
         * The `generatePlanDetails` method generates the appropriate details related to a user's plan (Basic,
         * Pro, Trial). Note that time-related data provided by Stripe, such as subscription start dates and 
         * renewal dates, are expressed as timestamps which are in turn expressed as *seconds* since the start
         * of the epoch. These timestamps are converted to human-readable dates using the `utils.js`
         * `convertTimestampToStandardDate` method. That method requires timestamps that are expressed as
         * *milliseconds* since the start of the epoch. Hence, Stripe timestamp values are multiplied by `1000`
         * in the logic below.
         * 
         * @param {string} `plan`: A string value with values `basic`, `pro`, or `trial`.
         * @param {object} `data`: The Stripe object with data on the user's current plan/subscription.
         * @return {object} `details`: Object with user-friendly plan details.
         * @throw undefined
         */
        let _templates = plans[plan].details;
        let _details = {};
        let _key;
        let _startDate;
        let _endDate;
        let _nextPaymentDate;
        let _cancelAtDate;
        for (_key in _templates) {
            let _label = StringUtils.capitalizeWords(_templates[_key]["label"]);
            let _item = _templates[_key]["data"];
    
            // Make any required replacements in `_templates`
            if (("basic" === plan) && ("amount" === _key)) {
                _item = _item.replace("$1", MathUtils.convertCurrUnitsToFormattedCurr(plans.basic.core.defaultPrice, plans.basic.core.defaultCurrency));
                _item = _item.replace("$2", plans.basic.core.interval);
            }
            if (("basic" === plan) && ("start" === _key)) {
                _item = _item.toUpperCase();
            }
            if (("basic" === plan) && ("end" === _key)) {
                _item = _item.toUpperCase();
            }
            if (("basic" === plan) && ("nextPayment" === _key)) {
                _item = _item.toUpperCase();
            }                    
            if (("pro" === plan) && ("amount" === _key)) {
                _item = _item.replace("$1", MathUtils.convertCurrUnitsToFormattedCurr(data["amount"], data["currency"]));
                _item = _item.replace("$2", data["interval"]);
            }
            if (("pro" === plan) && ("start" === _key)) {
                _startDate = DateUtils.convertTimestampToStandardDate(data["subCreated"] * 1000);
                _startDate = _startDate.toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric"});
                _item = _item.replace("$1", _startDate)
            }
            if (("pro" === plan) && ("end" === _key)) {
                _item = StringUtils.capitalizeSentences(_item);
            }
            if (("pro" === plan) && ("nextPayment" === _key)) {
                if (null !== data["cancellationDetails"]["cancelAt"]) {
                    let _text = "";
                    _cancelAtDate = DateUtils.convertTimestampToStandardDate(data["cancellationDetails"]["cancelAt"] * 1000);
                    _cancelAtDate = _cancelAtDate.toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric"});
                    _text = plans.pro.cancelText.at.replace("$1", _cancelAtDate);
                    _item = StringUtils.capitalizeSentences(_item.replace("$1", _text));
                } else {
                    _nextPaymentDate = DateUtils.convertTimestampToStandardDate(data["currentPeriodEnd"] * 1000);
                    _nextPaymentDate = _nextPaymentDate.toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric"});
                    _item = _item.replace("$1", _nextPaymentDate);
                }
            }
            if (("trial" === plan) && ("amount" === _key)) {
                _item = _item.replace("$1", MathUtils.convertCurrUnitsToFormattedCurr(plans.trial.core.defaultPrice, plans.trial.core.defaultCurrency));
                _item = _item.replace("$2", data["interval"]);                        
            }
            if (("trial" === plan) && ("start" === _key)) {
                _startDate = DateUtils.convertTimestampToStandardDate(data["trialDetails"]["start"] * 1000);
                _startDate = _startDate.toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric"});
                _item = _item.replace("$1", _startDate)
            }
            if (("trial" === plan) && ("end" === _key)) {
                _endDate = DateUtils.convertTimestampToStandardDate(data["trialDetails"]["end"] * 1000);
                _endDate = _endDate.toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric"});
                _item = _item.replace("$1", _endDate)
            }
            if (("trial" === plan) && ("afterTrial" === _key)) {
                let _text = "";
                // let _defaultPaymentMethod = data["defaultPaymentMethod"];
                let _endBehavior = data["trialDetails"]["missingPayment"];
                let _startPaymentDate = DateUtils.convertTimestampToStandardDate(data["currentPeriodEnd"] * 1000);
                _startPaymentDate = _startPaymentDate.toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric"});
                if ("create_invoice" === _endBehavior) {
                    _text = StringUtils.capitalizeSentences(plans.trial.afterTrialText["invoice"]);
                    _text = _text.replace("$1", MathUtils.convertCurrUnitsToFormattedCurr(data["amount"], data["currency"]));
                    _text = _text.replace("$2", data["interval"]);
                    _text = _text.replace("$3", _startPaymentDate);
                } else if ("cancel" === _endBehavior) {
                    _text = StringUtils.capitalizeSentences(plans.trial.afterTrialText["cancel"]);
                }
                _item = _item.replace("$1", _text);
            }                    
            _details[_key] = { "label": _label, "desc": _item };
        }
        return _details
    }    

    const optionClickHandler = (e) => {
        e.preventDefault();
        let _command_str = "";
        let _option = e.currentTarget.attributes.getNamedItem("data-option-key").value;

        if ("change-payment-method" === _option) {
            setOption("update");
        }
        if ("add-payment-method" === _option) {
            setOption("add");
        }
        if ("cancel-subscription" === _option) {
            setOption("cancel");
        }
        if ("cancel-subscription-after-trial" === _option) {
            setOption("cancel");
        }
        if ("reactivate-subscription-before-period-end" === _option) {
            setOption("reactivate");
        }
        if ("upgrade-to-pro" === _option) {
            setOption("upgrade");
        }
        if ("yes-cancel" === _option) {
            setOption("yes-cancel");
        }
 
        if (("change-payment-method" === _option) || ("add-payment-method" === _option)) {
            setIsVisibleButtonOptions((state) => !state);
            _command_str = '{"action": "create-setup-intent", "custId": "' + planData["custId"] + '"}';
            WebUtils.request(services["webServices"], _command_str)
            .then((response) => {
                let _data = JSON.parse(response);
                let _clientSecret = _data["client_secret"];
                setClientSecret(_clientSecret);
            })
        }
        if ("upgrade-to-pro" === _option) {
            setIsVisibleButtonOptions((state) => !state);
            setIsUpgrading(true);
            // _command_str = '{"action": "create-customer-subscription", "priceID": "default", "email": "' + user["email"] + '", "uid": "' + user["uid"] + '", "coupon": "none"}';   
            // WebUtils.request(services["webServices"], _command_str)
            // .then((response) => {
            //     let _data = JSON.parse(response);
            //     let _clientSecret = _data["client_secret"];
            //     let _planData = { ...planData }
            //     _planData["custId"] = _data["customer"]
                
            //     setPlanData(_planData);
            //     setClientSecret(_clientSecret);
            // })            
        }
        if ("reactivate-subscription-before-period-end" === _option) {
            _command_str = JSON.stringify({
                "action": "reactivate-subscription-before-period-end",
                "subId": planData["subId"],
                "plan": planData["plan"],
                "returnLimited": true
            });
            WebUtils.request(services["webServices"], _command_str)
            .then(() => {
                setIsCancelling(false);
                setStatusMessage(strContent.goodReactivate);
            })
            .catch(console.error)
        }
        if (("cancel-subscription" === _option) || ("cancel-subscription-after-trial" === _option)) {
            setIsModalVisible(true);
        }
        if ("yes-cancel" === _option) {
            _command_str = JSON.stringify({
                "action": "cancel-subscription-at-period-end",
                "subId": planData["subId"],
                "returnLimited": true
            });
            WebUtils.request(services["webServices"], _command_str)
            .then(() => {
                setIsCancelling(true);
                setStatusMessage(strContent.goodCancel);
            })
            .catch(console.error)
        }
    }

    const callPaymentIntent = (e) => {
        e.preventDefault();
        // Set the command string
        let _action;
        if ("" !== priceID) {
            // `priceID` from coupon check
            _action = '{"action": "create-customer-subscription", "priceID": "' + priceID + '", "email": "' + user["email"] + '", "uid": "' + user["uid"] + '", "coupon": "' + coupon + '"}';
        } else {
            // No `priceID`
            _action = '{"action": "create-customer-subscription", "priceID": "default", "email": "' + user["email"] + '", "uid": "' + user["uid"] + '", "coupon": "none"}';
        }
        WebUtils.request(services["webServices"], _action)
        .then((response) => {
            let _data = JSON.parse(response);
            let _clientSecret = _data["client_secret"];
            let _planData = { ...planData }
            _planData["custId"] = _data["customer"]
            
            setPlanData(_planData);
            setAmount(_data["amount"]);
            setClientSecret(_clientSecret);
        })
    }

    useEffect(() => {
        let _command_str = '{"action": "retrieve-stripe-customer-by-firebase-uid", "uid": "' + user.uid +  '", "expand": "true", "returnLimited": "true"}';
        WebUtils.request(services["webServices"], _command_str)
        .then((response) => {
            let _data = StringUtils.cleanCRNL(response);
            _data = JSON.parse(_data);

            if (("data" in _data) && (false === _data["data"])) {
                // Customer who did not sign up is a basic plan customer
                _data["plan"] = "basic";
                _data["custID"] = null;
                setPlanData(_data);
            } else if (("subStatus" in _data) && (null === _data["subStatus"])) {
                // Customer who had their subscription end is a basic plan customer
                _data["plan"] = "basic";
                setPlanData(_data);
            } else if (("subStatus" in _data) && ("incomplete" === _data["subStatus"])) {
                // Customer who did not complete Stripe payment is a basic plan customer
                _data["plan"] = "basic";
                setPlanData(_data);
            } else {              
                // Inveyo Pro Trial customers
                if (("delinquent" in _data) && (false === _data["delinquent"]) && (null === _data["trialDetails"]["start"])) {
                    _data["plan"] = "pro";
                    setPlanData(_data);
                }
                // Inveyo Pro customers
                if (("delinquent" in _data) && (false === _data["delinquent"]) && (null !== _data["trialDetails"]["start"])) {
                    _data["plan"] = "trial";
                    setPlanData(_data);
                }
            }
            return _data
        })
        .then((data) => {
            // Check if the `hasPaymentMethod` flag should be set
            if (false !== data["data"]) {            
                if (typeof(data["defaultPaymentMethod"]) === "string") {
                    setHasPaymentMethod(true);
                }
            }
            return data
        })
        .then((data) => {
            // Check if the `isCancelling` flag should be set
            /**
             * Note: The web request `retrieve-stripe-customer-by-firebase-uid` calls the `StripeUtils.search_for_customer` class method.
             * That method in turn calls the `StripeUtils.retrieve_customer` class method. The `StripeUtils.retrieve_customer` method 
             * checks if a user has an existing subscription. If the user does not have an existing subscription, the method sets 
             * `_response["cancellationDetails"] = None`. This means `data["cancellationDetails"]` will be `null` if no subscription was
             * found by the server `StripeUtils.retrieve_customer` method.
             */
            if ((false !== data["data"]) && (null !== data["cancellationDetails"])) {            
                if (null !== data["cancellationDetails"]["canceledAt"]) {
                    setIsCancelling(true);
                }
            }
            return data
        })
        .then((data) => {
            let _title = "";
            if ("basic" === data["plan"]) {
                _title = generatePlanTitle("basic");
            } 
            if ("pro" === data["plan"]) {
                _title = generatePlanTitle("pro");
            }
            if ("trial" === data["plan"]) {
                _title = generatePlanTitle("trial")
            }

            return { data: data, title: _title }
        })
        .then(({ data, title }) => {
            let _details = null;
            if ("basic" === data["plan"]) {
                _details = generatePlanDetails("basic", data);
            }
            if ("pro" === data["plan"]) {
                _details = generatePlanDetails("pro", data);
            }
            if ("trial" === data["plan"]) {
                _details = generatePlanDetails("trial", data);
            }

            let _planStringsObj = {};
            _planStringsObj["title"] = title;
            _planStringsObj["details"] = _details;
            setPlanStrings(_planStringsObj);
        })
        .catch(console.error)
    }, [user.uid, isCancelling, hasMadeUpdateOrUpgrade]);

    // Effects
    useEffect(() => {
        // Load Stripe
        setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_API_KEY));
    }, []);

    return(
        <Fragment>
            <h2 className = "mt-3 mb-3 text-capitalize">{ strings.formContent.settings.plan.title }</h2>
            { statusMessage && <Alert variant = "info" className = "col-md-4 mb-3">{ statusMessage }</Alert> }
            <div className = "fs-5 fw-bold mb-3">
                <span>{ (null !== planStrings["title"]) && planStrings["title"] }</span>
            </div>
            <div className = "fs-5 mb-3">
                {
                    (null !== planStrings["details"]) && Object.keys(planStrings["details"]).map((_key, i) => {
                        return(
                            <Fragment>
                                <div key = { `plan-detail-label-${i}` } className = "fw-semibold">{ planStrings["details"][_key]["label"]}:</div>
                                <div key = { `plan-detail-desc-${i}` } className = { [styles.emph, "mb-1"].join(" ") }>{ planStrings["details"][_key]["desc"]}</div>
                            </Fragment>
                        )
                    })
                }
            </div>
            <div className = "col-md-4">
                {
                    (isUpgrading && !hasMadeUpdateOrUpgrade) && (
                        <Fragment>
                            <div className = "form-floating mb-3">
                                <Coupon strings = { strings } setPriceID = { setPriceID } setIsCouponValid = { setIsCouponValid } setCouponStatusMessage = { setCouponStatusMessage } coupon = { coupon } setCoupon = { setCoupon }></Coupon>
                                { (couponStatusMessage !== "" && couponStatusMessage !== strContent.coupCheckNotice ) && (<div className = "small mt-1"><FontAwesomeIcon icon = { isCouponValid ? "fa-solid fa-check" : "fa-solid fa-circle-xmark" } className = { isCouponValid ? pStyles.valid : pStyles.invalid }></FontAwesomeIcon>&nbsp;{ couponStatusMessage }</div>)}
                                { (couponStatusMessage !== "" && couponStatusMessage === strContent.coupCheckNotice ) && (<div className = "small mt-1"><FontAwesomeIcon icon = "fa-solid fa-circle-pause"></FontAwesomeIcon>&nbsp;{ couponStatusMessage }</div>)}                    
                            </div>
                            {/** Hide the `Next: Payment` button once client secret is generated */}
                            {
                                (null === clientSecret) && (
                                    <div className = "d-grid gap-2">
                                        <button type = "submit" className = "btn btn-secondary pt-3 pb-3 fs-5" disabled = { strContent.coupCheckMsg === couponStatusMessage || (("none" !== coupon) && ("" === couponStatusMessage)) } onClick = { (e) => callPaymentIntent(e) }>{ strContent.submitPymtLbl }</button>
                                    </div>
                                )
                            }
                        </Fragment>
                    )
                }
            </div>
            <div className = "col-md-4">
                {
                    (clientSecret && stripePromise && !hasMadeUpdateOrUpgrade) && (
                        <Elements stripe = { stripePromise } options = { clientSecret }>
                            <UpdatePaymentForm
                                option = { option }
                                amount = { amount }
                                custId = { planData["custId"] }
                                paymentMethod = { planData["defaultPaymentMethod"] }
                                clientSecret = { clientSecret }
                                setStatusMessage = { setStatusMessage }
                                setHasMadeUpdateOrUpgrade = { setHasMadeUpdateOrUpgrade }
                                setClientSecret = { setClientSecret }
                                setIsVisibleButtonOptions = { setIsVisibleButtonOptions }
                                strings = { strings }
                                setJustUpgraded = { setJustUpgraded }>
                            </UpdatePaymentForm>
                        </Elements>
                    )
                }
            </div>            
            <div className = "col-12">
                {
                    (null !== planData && isVisibleButtonOptions) && (
                        <SettingsPlanPageButtons
                            plans = { plans }
                            planData = { planData }
                            isCancelling = { isCancelling }
                            hasPaymentMethod = { hasPaymentMethod }
                            optionClickHandler = { optionClickHandler }>
                        </SettingsPlanPageButtons>
                    )
                }
            </div>
            <CancelConfirmationModal show = { isModalVisible } handlers = {{ toggle: toggleModal, click: optionClickHandler }} strings = { strings }></CancelConfirmationModal>
        </Fragment>
    )
}

export default SettingsPlanPage