import { Fragment, useContext, useState } from "react";
import ReportTableDataWrapper from "./table-report-data-wrapper";
import Form4Modal from "./form4-filing-modal";
import { UserContext } from "./user-context";
import { WebUtils } from "../scripts/utils";
import services from "../scripts/services";

const ReportTableRowWrapper = ({ rowData, rowIndex, isMobile, tabTitle, strings }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form4Data, setForm4Data] = useState(null);
    const userContext = useContext(UserContext);

    const toggleModal = () => {
        if (true === isModalVisible) {
            // Reset `form4Data` to `null`
            setForm4Data(null);
        }        
        setIsModalVisible((state) => !state);
    }    
    
    const reportTableRowClickHandler = (e, accessionNum, issuerTicker) => {
        if (userContext.isPro) { // Display Form 4 details if pro user
            // Strip suffix from report accession number
            let lastHyphenIndex = accessionNum.lastIndexOf("-");
            accessionNum = accessionNum.slice(0, lastHyphenIndex);

            let _command = {
                action: "get-form-4-data",
                accessionNum: accessionNum,
                ticker: issuerTicker
            }
            let _command_str = JSON.stringify(_command);
            WebUtils.request(services.webServices, _command_str)
            .then((response) => {
                let _data = JSON.parse(response);

                if ("error" in _data) {
                    return false
                } else {
                    // Append accession number to `_data`
                    _data["accession_number"] = accessionNum;

                    setForm4Data(_data);
                    return true
                }
            })
            .then((isDataSet) => {
                if (isDataSet) {
                    setIsModalVisible(true);
                }
                // if `isDataSet` === `false`, don't display the modal; an error occured.
            })
            .catch(console.error)
        }
    }
    
    return(
        <Fragment>
            <ReportTableDataWrapper
                rowData = { rowData }
                rowIndex = { rowIndex }
                isMobile = { isMobile }
                tabTitle = { tabTitle }
                handlers = {{ click: reportTableRowClickHandler }}>
            </ReportTableDataWrapper>
            {
                (null !== form4Data) && <Form4Modal show = { isModalVisible } handlers = {{ toggle: toggleModal }} form4Data = { form4Data } strings = { strings }></Form4Modal>
            }
        </Fragment>
    )
}

export default ReportTableRowWrapper