import Entities from "./entities";
import Reports from "./reports";
import Articles from "./articles";

const ids = {
  "area": { "id": "docArea" }
}

const Doc = ({ isAdvancedSearchVisible, searchParameters, allIndustries, strings, isMobile, user, isProUser, justVerified, justUpgraded, youngestReportPeriod, selectedSubNavOption, articles }) => {
  switch (selectedSubNavOption) {
    case (0):
      if ("entity" === searchParameters["intent"]) {
        return(
          <div id = { ids.area.id } className = "mt-3">
            <Entities
              isAdvancedSearchVisible = { isAdvancedSearchVisible }
              searchParameters = { searchParameters }
              strings = { strings }
              isMobile = { isMobile }
              user = { user }
              isProUser = { isProUser }
              justVerified = { justVerified }
              justUpgraded = { justUpgraded }>
            </Entities>
          </div>
        )
      };
      if ("report" === searchParameters["intent"]) {
        return(
          <div id = { ids.area.id } className = "mt-3">
            <Reports
                isAdvancedSearchVisible = { isAdvancedSearchVisible }
                searchParameters = { searchParameters }
                industries = { allIndustries }
                strings = { strings }
                isMobile = { isMobile }
                user = { user }
                isProUser = { isProUser }
                justVerified = { justVerified }
                justUpgraded = { justUpgraded }
                youngestReportPeriod = { youngestReportPeriod }>
            </Reports>
          </div>
        )
      };
      break;
    case(1):
      return(
        <Articles articles = { articles } entity = { searchParameters["entity"] } strings = { strings }></Articles>
      )
    default:
      // pass
  }
}

export default Doc