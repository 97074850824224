import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { StringUtils } from "../scripts/utils";
import styles from "./logo-area-nav-option-wrapper.module.css";

const LogoAreaNavOptionWrapper = ({ handlers, label, icon, navKey, LANGS }) => {
    if ("side-panel-menu" === navKey) {
        return (
            <li className = "nav-item">
                <button data-nav-key = { navKey } type = "button" className = { [styles.navItemContainer, "btn"].join(" ") } onClick = { (e) => handlers.nav.click(e) }>
                    <div className = "text-center">
                        <FontAwesomeIcon icon = { icon } className = { [styles.navIcon, "fs-3"].join(" ") }></FontAwesomeIcon>
                    </div>
                    <div className = { styles.navLabel }>{ label }</div>
                </button>
            </li>
        )
    }
    if ("language" === navKey) {
        return (
            <Dropdown>
                <Dropdown.Toggle variant = "secondary" className = { styles.langDropdown }>
                    <FontAwesomeIcon icon = { icon } className = { [styles.navIcon, "fs-3"].join(" ") }></FontAwesomeIcon>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        Object.keys(LANGS).map((key, i) => {
                            return (
                                <Dropdown.Item key = { `language-${i}` } onClick = {(e) => { handlers.lang.click(e) }}>{ StringUtils.capitalizeWords(LANGS[key]) }</Dropdown.Item>
                            )
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

export default LogoAreaNavOptionWrapper