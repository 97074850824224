import React, { useMemo, useState } from "react";
import styles from "./table.module.css";
import TableHeaderWrapper from "./table-header-wrapper";
import ReportTableRowWrapper from "./table-report-row-wrapper";
import { setTitleAndSubTitle } from "./set-report-title.js";
import { MathUtils } from "../scripts/utils";
import urlData from "../scripts/urls";

// GLOBALS
let INDUSTRIES = [];

const checkTransactionValueAgainstRange = (transactionValue, min, max, cast, decimals) => {
    /**
     * The `checkTransactionValueAgainstRange` method checks if a transaction value is valid
     * against a supplied range.
     * 
     * @param {string} `transactionValue`: The transaction value to check expressed as a string.
     * @param {string} `min`: The range minimum expressed as a string.
     * @param {string} `max`: The range maximum expressed as a string.
     * @param {string} `cast`: If `float`, `min and `max` parameters are cast to floats; otherwise, cast to ints.
     * @param {int} `decimals`: The number of decimals to use when converting `transactionValue` to a number.
     * @return {boolean} _: `true` if `transactionValue` is within range; `false` otherwise.
     * @throw {Error} `err`
     */
    try {
        // Initialize flag for range validity
        let _validRangeFlag = null;

        // Cast min, max values to int or float depending on cast parameter
        if ("float" === cast) {
            min = parseFloat(min);
            max = parseFloat(max);
        } else {
            min = parseInt(min);
            max = parseInt(max);
        }

        // Check if min or max are NaN
        if ((isNaN(min)) && (isNaN(max))) {
            _validRangeFlag = false;
        }

        // Check if we have a valid range
        if (min < max) {
            // Min is less than or equal to max
            _validRangeFlag = true;
        } else {
            _validRangeFlag = false;
        }

        // Return based on `_validRangeFlag` and supplied price
        if (_validRangeFlag) {
            // Convert the price to a number
            let _num = MathUtils.stringConvertToNumber(transactionValue, cast, "$", ",", decimals);

            // Check if _num is within range
            if((_num >= min) && (_num <= max)){
                return true; // The price is within the desired range
            }
            return false; // The price is outside the desired range
        }
        return true; // If the range was invalid, assume all transaction prices should be displayed
    } catch (err) {
        console.error(err);
    }
}

const checkTransactionAgainstTitle = (transactionTitle, selectedTitle, strings, stringsKey) => {
    /**
     * The `checkTransactionAgainstTitle` method checks if a supplied transaction owner title
     * is valid against a title value selected via the Advanced Search filter.
     * 
     * @param {string} `transactionTitle`
     * @param {string} `selectedTitle`
     * @param {object} `strings`
     * @param {string} `stringsKey`: The key for selecting within the `strings` object.
     * @return {boolean} _: `true` if the `transactionTitle` matches the `selectedTitle`; `false` otherwise.
     * @throw {Error} `err`
     */

    try {
        // If selected title is "all", return true
        if (("all" === selectedTitle) || (null == selectedTitle)) {
            return true;
        }

        // Initialize _validTitleFlag to false
        let _validTitleFlag = false;

        // Get the array of title variations for the selected title
        let _titleVariations = strings[stringsKey][selectedTitle]["strings"];

        // Change transaction title to lower case
        let _lowerCaseTitle = transactionTitle.toLowerCase();

        // Check if the transaction title matches anything in the title variations list
        _titleVariations.forEach((_titleVariation) => {
            if (_lowerCaseTitle.indexOf(_titleVariation) > -1) {
                _validTitleFlag = true;
            }
        });
        
        // Return true/false based on _validTitleFlag
        if (_validTitleFlag) {
            return true;
        } else {
            return false;
        }
    } catch (err) {
        console.error(err);
    }
}

// Utility function to check transaction against input text
const checkTransactionAgainstInput = (transactionText, inputText) => {
    // If input text is null or "", return true
    if ((null === inputText) || ("" === inputText)) {
        return true;
    }

    // Convert transaction text and input text to lower case
    let _lowerCaseTransactionText = transactionText.toLowerCase();
    let _lowerCaseInputText = inputText.toLowerCase();

    // Check if the input text matches the supplied transaction text
    if (_lowerCaseTransactionText.indexOf(_lowerCaseInputText) > -1) {
        return true;
    }
    return false;
}

// Utility function to check transaction against industry
const checkTransactionAgainstIndustry = (transactionIndustry, selectedIndustry) => {
    // If `selectedIndustry` is `null`, return `true`.
    if (null === selectedIndustry) {
        return true
    }
    
    // Set `transactionIndustry` and `selectedIndustry` to lower case
    let _lowerCaseTransactionIndustry = transactionIndustry.toLowerCase();
    let _lowerCaseSelectedIndustry = selectedIndustry.toLowerCase();

    // If selected industry is not "all", check if the selected industry matches the transaction industry
    if ((_lowerCaseSelectedIndustry === _lowerCaseTransactionIndustry) || ("all" === _lowerCaseSelectedIndustry)) {
        return true;
    }
    return false;
}

// Utility function to check transaction against advanced search parameters
const checkTransactionAgainstSearchParameters = (transaction, parameters, strings) => {
    // Initialize flags
    let _validPrice = false;
    let _validTradeValue = false;
    let _validIndustryValue = false;
    let _validOwnerTitle = false;
    let _validOwnerName = false;

    // Get search parameters
    let _selectedSharePriceMin = parameters["sharePriceMin"];
    let _selectedSharePriceMax = parameters["sharePriceMax"]
    let _selectedLiquidityMin = parameters["liquidityMin"];
    let _selectedLiquidityMax = parameters["liquidityMax"];
    let _selectedIndustry = parameters["industry"];
    let _selectedOwnerTitle = parameters["owner"];
    let _selectedOwnerName = parameters["ownerName"];

    // Get transaction data
    let _reportingPerson = transaction["reportingPerson"];
    let _reportingPersonTitle = transaction["reportingPersonTitle"];
    let _price = transaction["price"];
    let _tradeValue = transaction["totalValue"];
    let _industry = transaction["industry"];

    // Share price
    _validPrice = checkTransactionValueAgainstRange(_price, _selectedSharePriceMin, _selectedSharePriceMax, "float", 2);

    // Trade value
    _validTradeValue = checkTransactionValueAgainstRange(_tradeValue, _selectedLiquidityMin, _selectedLiquidityMax, "int", 0);

    // Industry value
    _validIndustryValue = checkTransactionAgainstIndustry(_industry, _selectedIndustry);

    // Owner title
    _validOwnerTitle = checkTransactionAgainstTitle(_reportingPersonTitle, _selectedOwnerTitle, strings, "ownerOptions");

    // Owner name
    _validOwnerName = checkTransactionAgainstInput(_reportingPerson, _selectedOwnerName);

    if ((_validPrice) && (_validTradeValue) && (_validIndustryValue) && (_validOwnerTitle) && (_validOwnerName)) {
        return true
    } else {
        return false
    }
}

// Utility function to extract unique industries
const setUniqueIndustries = (industry) => {
    if (INDUSTRIES.indexOf(industry) === -1) {
        INDUSTRIES.push(industry);
    }

    // Add "all" option.
    if (INDUSTRIES.indexOf("all") === -1) {
        INDUSTRIES = ["all", ...INDUSTRIES];
    }

    return
}

// Utility function to add rows to table data array
const addTableDataRows = (rows, rawData, accessionNumKeys, urlData, searchParameters, isAdvancedSearchVisible, strings, reportKey) => {
    if(accessionNumKeys.length > 0){
        // Get the first accession number in the remaining accession numbers list
        let _key = accessionNumKeys[0];

        // Initialize _pushTransaction
        let _pushTransaction = true;        

        // 1a. Grab fields.
        let _acceptanceDate = rawData[_key]["acceptance_date"];
        let _acceptanceTime = rawData[_key]["acceptance_time"];
        let _issuerTicker = rawData[_key]["ticker"];
        let _issuer = rawData[_key]["issuer"];
        let _industry = rawData[_key]["sic_desc"];
        let _reportingPerson = rawData[_key]["reporting_person"];
        let _reportingPersonTitle = rawData[_key]["reporting_person_title"];
        let _price = rawData[_key]["price"];
        let _amount = rawData[_key]["amount"]; 
        let _tradeValue = rawData[_key]["total_value"];
        let _owned = rawData[_key]["owned"];
        let _delta = rawData[_key]["owned_delta"];

        // 1b. Send industry to `setUniqueIndustries` method
        setUniqueIndustries(_industry);

        // 2. Perform calculations on selected fields
        _delta = _delta * 100;

        // 3. Format selected fields
        _price = MathUtils.numberConvertToString(_price, ["ADD_SYMBOL", "DECIMALS"], "$", "", 2);
        _amount = MathUtils.numberConvertToString(parseInt(_amount.replace(/,/g, "")), ["SEPARATOR"], "", ",", 0); // `parseInt` will automatically cut off fractional shares (i.e. anything after a decimal)
        _owned = MathUtils.numberConvertToString(parseInt(_owned.replace(/,/g, "")), ["SEPARATOR"], "", ",", 0); // `parseInt` will automatically cut off fractional shares (i.e. anything after a decimal)
        _tradeValue = MathUtils.numberConvertToString(_tradeValue, ["ADD_SYMBOL", "SEPARATOR"], "$", ",", 0);
        _delta = MathUtils.numberConvertToString(_delta, ["PERCENT"], "", "", 0);

        // 4. Add prefixes
        let _prefix = reportKey.indexOf("acq") > -1 ? "+" : "";
        let _prefixedDelta = _prefix + _delta;

        // 5. Add all fields to an object for this table 1 transaction
        let _transaction = {
            "accessionNum": _key,
            "acceptanceDate": _acceptanceDate,
            "acceptanceTime": _acceptanceTime,
            "issuerTicker": _issuerTicker,
            "issuer": _issuer,
            "industry": _industry,
            "reportingPerson": _reportingPerson,
            "reportingPersonTitle": _reportingPersonTitle,
            "price": _price,
            "amount": _amount,
            "totalValue": _tradeValue,
            "amountOwned": _owned,
            "delta": _prefixedDelta
        }

        // Check the transaction if using advanced search parameters
        if (1 === isAdvancedSearchVisible) {
            _pushTransaction = checkTransactionAgainstSearchParameters(_transaction, searchParameters, strings);
        }

        // Add the transaction
        if (_pushTransaction) {
            rows.push(_transaction);
        }

        // Slice the accessionNumKeys array
        let remainingAccessionNumKeys = accessionNumKeys.slice(1);

        return addTableDataRows(rows, rawData, remainingAccessionNumKeys, urlData, searchParameters, isAdvancedSearchVisible, strings, reportKey);    
    } else {
        return rows
    }
};

// Build table function
const buildTableData = (data, urlData, searchParameters, isAdvancedSearchVisible, strings, reportKey) => {
    // Set accession numbers for this report
    let _accessionNumKeys = Object.keys(data);

    // Call the addTableDataRows function to add data rows to `tableData`
    let _tableData = addTableDataRows([], data, _accessionNumKeys, urlData, searchParameters, isAdvancedSearchVisible, strings, reportKey);

    // Return table data
    return _tableData
};

const ReportTable = ({ tabTitle, report, data, isAdvancedSearchVisible, searchParameters, industries, strings, isMobile, youngestReportPeriod }) => {    
    // Set headers
    let headers = strings.reportTableHeaders;
    
    // Assign copy of `industries` hook to global `INDUSTRIES`
    INDUSTRIES = industries.current.length !== 0 ? [...industries.current] : [];

    // Table data hook
    let _tableData = buildTableData(data, urlData, searchParameters, isAdvancedSearchVisible, strings, report);

    // Set `industries` reference hook
    industries.current = INDUSTRIES;

    // Custom useSortedData hook
    const useSortedData = (data) => {
        const [sortConfig, setSortConfig] = useState({"field": "acceptanceDate", "direction": "descending"});

        const sortedData = useMemo(() => {
            const dateSortFields = ["acceptanceDate"];
            // const timeSortFields = ["acceptanceTime"];
            const textSortFields = ["reportingPerson", "reportingPersonTitle", "issuerTicker", "issuer", "industry"];
            const numSortFields = ["price", "amount", "totalValue", "amountOwned", "delta"];            
            
            let _dataAfterSort = [...data];
            
            if (dateSortFields.indexOf(sortConfig["field"]) > -1) {
                _dataAfterSort.sort((a,b) => {
                    let _date1 = new Date(a[sortConfig["field"]]);
                    let _date2 = new Date(b[sortConfig["field"]]);
        
                    if (sortConfig["direction"] === "ascending") {
                        return _date1 - _date2;
                    } else {
                        return _date2 - _date1;
                    }                
                });
            } else if (textSortFields.indexOf(sortConfig["field"]) > -1) {
                _dataAfterSort.sort((a,b) => {
                    let _text1 = a[sortConfig["field"]];
                    let _text2 = b[sortConfig["field"]];
                    
                    if (_text1.localeCompare(_text2) === -1) {
                        return sortConfig["direction"] === "ascending" ? -1  : 1;
                    } else if (_text1.localeCompare(_text2) === 1) {
                        return sortConfig["direction"] === "ascending" ? 1 : -1;
                    } else {
                        return 0;
                    }
                });
            } else if (numSortFields.indexOf(sortConfig["field"]) > -1) {
                _dataAfterSort.sort((a,b) => {
                    let numString1 = a[sortConfig["field"]];
                    let numString2 = b[sortConfig["field"]];
                    
                    // Remove special characters, except period.
                    numString1 = numString1.replace(/[$,%\-+]/g, "");
                    numString2 = numString2.replace(/[$,%\-+]/g, "");

                    // Check if this is an integer or floating-point value.
                    let numType = sortConfig["field"] === "price" ? "float" : "int";

                    // Cast numString1 and numString2 to int or float.
                    let num1, num2;
                    if ("float" === numType) {
                        num1 = numString1 !== "N/A" ? parseFloat(numString1) : 0;
                        num2 = numString2 !== "N/A" ? parseFloat(numString2) : 0;
                    } else {
                        num1 = numString1 !== "N/A" ? parseInt(numString1) : 0;
                        num2 = numString2 !== "N/A" ? parseInt(numString2) : 0;
                    }

                    if (sortConfig["direction"] === "ascending") {
                        return num1 - num2;
                    } else {
                        return num2 - num1;
                    }
                });
            } else {
                _dataAfterSort.sort();
            }
            return _dataAfterSort
        }, [data, sortConfig]);

        const setTableDataSort = (e, field) => {
            let _direction;
            
            if (field !== sortConfig["field"]) {
                _direction = "descending";
            } else {
                if (sortConfig["direction"] === "descending") {
                    _direction = "ascending";
                    // console.log("setting field to ", field, " and direction to ", _direction);
                } else {
                    _direction = "descending";
                    // console.log("setting field to ", field, " and direction to ", _direction);
                }
            }
            setSortConfig({ "field": field, "direction": _direction });
        }

        return { tableData: sortedData, setTableDataSort, sortConfig: sortConfig }
    }

    const { tableData, setTableDataSort, sortConfig } = useSortedData(_tableData);

    // Set the table title.
    let [_tableTitle, _tableSubTitle] = setTitleAndSubTitle(tabTitle, searchParameters, strings, tableData, youngestReportPeriod);

    return(
        <React.Fragment>
            <h2 className = { ["mt-3", styles.tableTitle].join(" ") }>
                { `${_tableTitle}` }
            </h2>
            <h6 className = { styles.tableSubTitle }>
                { `${_tableSubTitle}` }
            </h6>
            <div className = { styles.tableContainer }>
                <table>
                    <thead>
                        <tr className = { styles.tableHeader }>
                            {
                                Object.keys(headers).map((headerKey, index) => <TableHeaderWrapper key = { `${report}-${headerKey}` } headerKey = { headerKey } index = { index } headers = { headers } handlers = { {click: setTableDataSort} } sortConfig = { sortConfig } isMobile = { isMobile }></TableHeaderWrapper>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableData.map((rowData, rowIndex) => <ReportTableRowWrapper key = { rowData["accessionNum"] } rowData = { rowData } rowIndex = { rowIndex } isMobile = { isMobile } tabTitle = { tabTitle } strings = { strings }></ReportTableRowWrapper>)
                        }
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}

export default ReportTable