import { Link } from "react-router-dom";
import styles from "./articles.module.css";
import wallpaper from "../img/blue-wallpaper-27.jpg";

const ArticleCard = ({ index, article, badges, selectedNav, strings }) => {
  const IMG_URL_PREFIX = "https://";

  if ("all" === selectedNav) {
    return(
      <div data-article-key = { `articles-grid-item-${index}` } data-article-id = { article.id } data-article-slug = { article.slug } className = { styles.articlesGridCard }>  
        <Link to = { `articles/${article.slug}` }>
          <div className = "card card-body">
            <img className = "w-100" alt = "" src = { (article.hasOwnProperty("img")) ? IMG_URL_PREFIX + article.img : wallpaper }></img>
            <div className = "fw-bold text-center mt-3">{ article.title }&nbsp;&nbsp;<span className = { [styles.badge, "badge", "bg-primary"].join(" ") }>{ badges[index] }</span></div>
          </div>
        </Link>
      </div>
    )    
  }
  return(
    <div data-article-key = { `articles-grid-item-${index}` } data-article-id = { article.id } data-article-slug = { article.slug } className = { styles.articlesGridCard }>  
      <Link to = { `articles/${article.slug}` }>
        <div className = "card card-body">
          <img className = "w-100" alt = "" src = { (article.hasOwnProperty("img")) ? IMG_URL_PREFIX + article.img : wallpaper }></img>
          <div className = "fw-bold text-center mt-3">{ article.title }&nbsp;&nbsp;<span className = { [styles.badge, "badge", "bg-primary"].join(" ") }>{ strings.articleBadges[selectedNav]["label"] }</span></div>
        </div>
      </Link>
    </div>
  )
}

export default ArticleCard