const strings = {
    seo: {
        companySuffix: "Inveyo",
        title: "SEC Form 4 Insider Trading Screener",
        metaDesc: "Inveyo is an SEC Form 4 insider trading screener. Search by date for current and historical reports, or by stock symbol for nearly 6000 stocks."
    },
    universals: {
        timeSuffixes: { "am": "AM", "pm": "PM" },
        timeZones: { "est": "EST" },
        onTheHourMinutes: ":00"
    },
    logoAreaBranding: {
        name: "inveyo",
        shortName: "inveyo",
        version: "beta",
        tagline: "form 4 insider trading screener"
    },
    logoAreaNav: {
        nav1: { "label": "menu", "icon": "fa-solid fa-bars", "navKey": "side-panel-menu" },
        // nav2: { "label": "language", "icon": "fa-solid fa-globe", "navKey": "language" }
    },
    logoAreaUser: {
        nav1: { "label": "login", "icon": "fa-solid fa-right-to-bracket", "navKey": "login" },
        nav2: { "label": "signup", "icon": "fa-solid fa-user-plus", "navKey": "signup" },
        nav3: { "label": "verify", "icon": "fa-solid fa-envelope-open-text", "navKey": "verify" },
        nav4: { "label": "logout", "icon": "fa-solid fa-right-from-bracket", "navKey": "logout" },
        nav5: { "label": "settings", "icon": "fa-solid fa-cog", "navKey": "settings" }
    },
    subNav: {
        nav1: { "label": "form 4 screener", "icon": "", "navKey": "form-4-screener" },
        nav2: { "label": "articles", "icon": "", "navKey": "articles" }
    },
    sideNav: {
        nav1: { "label": "legend", "icon": "fa-solid fa-list", "navKey": "legend" },
        nav2: { "label": "settings", "icon": "fa-solid fa-cog", "navKey": "settings" },
        nav3: { "label": "mission", "icon": "fa-solid fa-flag", "navKey": "mission" },
        nav4: { "label": "help", "icon": "fa-solid fa-info-circle", "navKey": "help" },
        nav5: { "label": "contact us", "icon": "fa-solid fa-person-circle-question", "navKey": "contact-us" }
    },
    settingsNav: {
        nav1: { "label": "close", "icon": "", "navKey": "close" },
        nav2: { "label": "profile", "icon": "fa-solid fa-user", "navKey": "profile" },
        nav3: { "label": "plan", "icon": "fa-solid fa-file-invoice", "navKey": "plan" }
    },
    articlesNav: {
        nav1: { "label": "all", "icon": "", "navKey": "all" },
        nav2: { "label": "must read", "icon": "", "navKey": "must" }     
    },
    legend: {
        title: { "label": "transaction code legend" },
        colorCodes: {
            header: { "label": "color codes" },
            acqNonDeriv: { "key": "green", "label": "non-derivative acquisition or purchase" },
            acqDeriv: { "key": "blue", "label": "derivative acquisition or purchase" },
            disNonDeriv: { "key": "orange", "label" : "non-derivative disposition or sale" },
            disDeriv: { "key": "red", "label": "derivative disposition or sale" },
            other: { "key": "white", "label": "other" }
        },
        transactionCodes: {
            header: { "label": "transaction codes" },
            p: { "key": "p", "label": "open market or private purchase of securities" },
            s: { "key": "s", "label": "open market or private sale of securities" },
            v: { "key": "v", "label": "transaction voluntarily reported earlier than required" },
            a: { "key": "a", "label": "grant, award, or other acquisition" },
            d: { "key": "d", "label": "sale (or disposition) back to the issuer of the securities" },
            f: { "key": "f", "label": "payment of exercise price or tax liability by delivering or withholding securities" },
            i: { "key": "i", "label": "discretionary transaction at best possible price" },
            m: { "key": "m", "label": "exercise or conversion of derivative security" },
            c: { "key": "c", "label": "conversion of derivative security" },
            e: { "key": "e", "label": "expiration of short derivative position" },
            h: { "key": "h", "label": "expiration of long derivative position" },
            o: { "key": "o", "label": "exercise of out-of-the-money derivative securities" },
            x: { "key": "x", "label": "exercise or in-the-money or at-the-moeny derivative securities" },
            g: { "key": "g", "label": "gift" },
            l: { "key": "l", "label": "small acquisition" },
            w: { "key": "w", "label": "acquisition or disposition by will" },
            z: { "key": "z", "label": "deposit into or withdrawal from voting trust" },
            j: { "key": "j", "label": "other acquisition or disposition" },
            k: { "key": "k", "label": "transaction in equity swap or similar instrument" },
            u: { "key": "u", "label": "disposition due to a tender of shares in a change of control transaction" }            
        }
    },
    search: {
        filter: { "label": "filter", "icon": "fa-solid fa-filter" },
        search: { "label": "", "icon": "fa-solid fa-magnifying-glass" },
        input: { "label": "search" },
        autocomplete: { "entity": { "icon": "fa-solid fa-building" }, "report": { "icon": "fa-solid fa-file-text" }, "article": { "icon": "fa-solid fa-newspaper" }},
        prettyReportName: { "prefix": "reports for" }
    },
    advancedSearch: {
        entityOrReports: { "label": "ticker/CIK/report", "icon": "", "placeholder": "ticker, CIK or report" },
        sharePriceMin: { "label": "share price ($)", "icon": "", "placeholder": "min" },
        sharePriceMax: { "label": "", "icon": "", "placeholder": "max" },
        liquidityMin: { "label": "trade value ($)", "icon": "", "placeholder": "min" },
        liquidityMax: { "label": "", "icon": "", "placeholder": "max" },
        industry: { "label": "industry", "icon": "", "placeholder": "" },
        filingDate: { "label": "filing date", "icon": "", "placeholder": "" },
        security: { "label": "security title", "icon": "", "placeholder": "" },
        owner: { "label": "owner title", "icon": "", "placeholder": "" },
        ownerName: { "label": "owner name", "icon": "", "placeholder": "name" },
        transaction: { "label": "transaction type", "icon": "" },
        // transactionCodeButtons: [
        //    { "code": "a", "label": "acquisition" },
        //    { "code": "d", "label": "disposition" },
        //    { "code": "m", "label": "option" },
        //    { "code": "i", "label": "individual" },
        //    { "code": "j", "label": "joint" } 
        // ],
        transactionCodeButtons: [
            { "code": "p", "label": "purchase" },
            { "code": "s", "label": "sale" },
            { "code": "a", "label": "acquisition" },
            { "code": "d", "label": "disposition" },
            { "code": "m", "label": "derivative" } 
        ],
        submitButton: { "label": "submit" },
        resetButton: { "label": "reset" }
    },
    filingDateOptions: {
        "all dates": { "abbr": "all" },
        "latest day": { "abbr": "latest" },
        "latest 3 days": { "abbr": "latest 3" },
        "last 1 week": { "abbr": "last week" },
        "last 1 year": { "abbr": "last year" },
        "last 2 years": { "abbr": "last 2 years" },
        "last 3 years": { "abbr": "last 3 years" },
        "last 4 years": { "abbr": "last 4 years" },
        "last 5 years": { "abbr": "last 5 years" }
    },
    tradeDateOptions: {
        "all dates": { "abbr": "all" },
        "latest day": { "abbr": "latest" },
        "latest 3 days": { "abbr": "latest 3" },
        "last 1 week": { "abbr": "last week" },
        "last 1 year": { "abbr": "last year" },
        "last 2 years": { "abbr": "last 2 years" },
        "last 3 years": { "abbr": "last 3 years" },
        "last 4 years": { "abbr": "last 4 years" },
        "last 5 years": { "abbr": "last 5 years" }
    },
    securityOptions: {
        "all": { "abbr": "all", "strings": [] },
        "common stock": { "abbr": "stock", "strings": ["common stock"] },
        "restricted stock unit": { "abbr": "rsu", "strings": ["restricted stock unit", "rst. stock unit", "RSU"] },
        "phantom stock unit": { "abbr": "psu", "strings": ["phantom stock unit", "PSU"] },
        "deferred stock unit": { "abbr": "dsu", "strings": ["deferred stock unit", "DSU"] },
        "stock option": { "abbr": "option", "strings": ["stock option", "employee stock option", "emp. stock option"] },
        "promised fee share": { "abbr": "pfs", "strings": ["promised fee share"] }
    },
    ownerOptions: {
        "all": { "abbr": "all", "strings": [] },
        "chief executive officer": { "abbr": "CEO", "strings": ["ceo", "chief executive officer"] },
        "president": { "abbr": "pres", "strings": ["pres", "president"] },
        "chief operating officer": { "abbr": "COO", "strings": ["coo", "chief operating officer"] },
        "chief financial officer": { "abbr": "CFO", "strings": ["cfo", "chief financial officer"] },
        "executive vice president": { "abbr": "EVP", "strings": ["evp", "executive vice president"] },
        "senior vice president": { "abbr": "SVP", "strings": ["svp", "sr. vp", "senior vice president"] },
        "vice president": { "abbr": "VP", "strings": ["vp", "vice president"] },
        "general counsel": { "abbr": "GC", "strings": ["gc", "general counsel"] },
        "director": { "abbr": "DIR", "strings": ["dir", "director"] },
        "10% owner": { "abbr": "10%", "strings": ["10%", "10% owner"] }
    },
    entityTableHeaders: {
        entityTableHeaders1: { "label": "filing date", "abbr": "date", "icon": "", "objName": "filingDate" },
        entityTableHeaders2: { "label": "trade date", "icon": "", "abbr": "trade", "objName": "earliestTransactionDate" },
        entityTableHeaders3: { "label": "ticker", "icon": "", "abbr": "ticker", "objName": "issuerTicker" },
        entityTableHeaders4: { "label": "reporting person", "icon": "", "abbr": "person", "objName": "reportingPerson" },
        entityTableHeaders5: { "label": "title", "icon": "", "abbr": "title", "objName": "reportingPersonTitle" },
        entityTableHeaders6: { "label": "insider type", "icon": "", "abbr": "type", "objName": "individualFilingCode" },
        entityTableHeaders7: { "label": "security title", "icon": "", "abbr": "security", "objName": "securityTitle" },
        entityTableHeaders8: { "label": "transaction", "icon": "", "abbr": "code", "objName": "transactionCode" },
        entityTableHeaders9: { "label": "price", "icon": "", "abbr": "price", "objName": "price" },
        entityTableHeaders10: { "label": "quantity", "icon": "", "abbr": "qty", "objName": "amount" },
        entityTableHeaders11: { "label": "trade value", "icon": "", "abbr": "value", "objName": "totalValue" },
        entityTableHeaders12: { "label": "owned", "icon": "", "abbr": "owned", "objName": "amountOwned" },
        entityTableHeaders13: { "label": "change", "icon": "", "abbr": "change", "objName": "delta" }
    },
    reportTableHeaders: {
        reportTableHeaders1: { "label": "acceptance date", "icon": "", "abbr": "date", "objName": "acceptanceDate" },
        reportTableHeaders2: { "label": "acceptance time", "icon": "", "abbr": "time", "objName": "acceptanceTime" },
        reportTableHeaders3: { "label": "ticker", "icon": "", "abbr": "ticker", "objName": "issuerTicker" },
        reportTableHeaders4: { "label": "issuer", "icon": "", "abbr": "issuer", "objName": "issuer" },
        reportTableHeaders5: { "label": "industry", "icon": "", "abbr": "ind", "objName": "industry" },
        reportTableHeaders6: { "label": "reporting person", "icon": "", "abbr": "person", "objName": "reportingPerson" },
        reportTableHeaders7: { "label": "title", "icon": "", "abbr": "title", "objName": "reportingPersonTitle" },
        reportTableHeaders8: { "label": "price", "icon": "", "abbr": "price", "objName": "price" },
        reportTableHeaders9: { "label": "quantity", "icon": "", "abbr": "qty", "objName": "amount" },
        reportTableHeaders10: { "label": "trade value", "icon": "", "abbr": "value", "objName": "totalValue" },
        reportTableHeaders11: { "label": "owned", "icon": "", "abbr": "owned", "objName": "amountOwned" },
        reportTableHeaders12: { "label": "change", "icon": "", "abbr": "change", "objName": "delta" }
    },
    entityMessages: {
        waiting: { "label": "Just a minute...fetching Form 4 data..." },
        loading: { "label": "Now loading data for $..." },
        noData: { "label": "No Form 4 data is available for $..." }
    },
    reportMessages: {
        waiting: { "label": "Just a minute...fetching Form 4 data..." },
        loading: { "label": "Now loading data for report period $..." },
        noData: { "label": "No report is available for $..." }
    },
    articleMessages: {
        waiting: { "label": "Just a minute...fetching articles data..." }
    },
    articleBadges : {
        must: { "label": "must read", "icon": "" },
        new: { "label": "new", "icon": "" },
        premium: { "label": "premium", "icon": "" },
        noBadge: { "label": "", "icon": "" }
    },
    tableTitles: {
        entity: {
            titleTemplate: "$1 ($2) form 4 data",
            subTitleTemplate: "(CIK: $1, Industry: $2)"
        },
        report: {
            titleTemplate: "$1 $2",
            subTitleTodayTemplate: "(Data as of $1; next fetch at $2)",
            subTitleEODTemplate: "(Data as of $1; next fetch on $2 at $3)",
            subTitleNewDayTemplate: "(Next fetch on $1 at $2)",
            "nd-acq": { "full": "latest buys", "abbr": "buys" },
            "nd-acq-100": { "full": "latest buys $100K+", "abbr": "buys $100K+" },
            "nd-dis": { "full": "latest sells", "abbr": "sells" },
            "nd-dis-100": { "full": "latest sells $100K+", "abbr": "sells $100K+" }
        }
    },
    notifications: {
        account: {
            notVerified: "you need to verify your email address. please check your email for a verification email. if you need another verification email, click `Verify` above.",
            sentVerification: "we sent a verification email to your email address. click the link in the email to verify your account.",
            justVerified: "thanks for verifying your email address!",
            upgradeRequestNoAccountHistReport: "historical reports are available for Pro users. create an account using `Signup` and select the Pro plan.",
            upgradeRequestBasicAccountHistReport: "historical reports are available for Pro users. you can upgrade via `Settings` > `Plans`."
        }
    },
    formContent: {
        passwordInputs: {
            passwordLabel: "enter a password",
            passwordPlaceholder: "password",
            confirmLabel: "confirm password",
            confirmPlaceholder: "confirm password",
            currentLabel: "enter your current password",
            currentPlaceholder: "password",
            newLabel: "enter your new password",
            newPlaceholder: "password",
            passwordHint: "1 uppercase letter, 1 number, and between 8 and 16 characters.",
        },
        otherInputs: {
            emailLabel: "enter your email address",
            emailPlaceholder: "yourname@example.com",
            couponLabel: "if you have a coupon code, enter it here",
            couponPlaceholder: "coupon code"            
        },
        buttons: {
            submitLabel: "submit",
            submitPaymentLabel: "next: payment",
            submitDoneLabel: "done",
            submitLoginLabel: "login",
            submitLogoutLabel: "logout",
            submitPayNow: "pay now",
            submitProcessing: "processing...",
            showUpdatePassword: "update password",
            yes: "yes",
            no: "no"
        },
        text: {
            signup: {
                text1: "need to sign up for an account?",
                text2: "click here."
            },
            forgot: {
                text1: "forgot your password?",
                text2: "click here."
            },            
            backToLogin: {
                text1: "already have an account?",
                text2: "click to login."
            },
            loadingCheckout: {
                text1: "loading the checkout form. please wait a moment.",
                text2: "please wait a moment."
            }
        },
        plans: {
            all: {
                current: { "label": "your current plan is $1."}
            },        
            basic: {
                core: { "label": "basic", "friendlyPrice": "free", "defaultPrice": "0", "interval": "month", "defaultCurrency": "usd" },
                details: {
                    amount: { "label": "price", "data": "$1/$2" },
                    start: { "label": "start date", "data": "n/a" },
                    end: { "label": "end date", "data": "n/a" }
                },
                options: {
                    upgradeNoPaymentMethod: { "label": "upgrade to pro", "for": ["not-cancelling", "no-payment-method"], "optionKey": "upgrade-to-pro" },
                    upgradeWithPaymentMethod: { "label": "upgrade to pro", "for": ["not-cancelling", "has-payment-method"], "optionKey": "upgrade-to-pro" }
                },
                features: {
                    reports: { "label": "buy & sell reports", "permission": "current day" },
                    filings: { "label": "company filings", "permission": "limited data" }
                }
            },
            pro: {
                core: { "label": "pro", "friendlyPrice": "$19.99/month", "defaultPrice": "1999", "interval": "month", "defaultCurrency": "usd" },
                details: {
                    amount: { "label": "price", "data": "$1/$2" },
                    start: { "label": "start date", "data": "$1" },
                    end: { "label": "end date", "data": "until cancelled. you can cancel anytime." },
                    nextPayment: { "label": "next payment", "data": "$1" },
                },
                cancelText: {
                    at: "your subscription will be cancelled on $1."
                },
                options: {
                    changePaymentMethod: { "label": "change payment method", "for": ["not-cancelling", "has-payment-method"], "optionKey": "change-payment-method" },
                    cancel: { "label": "cancel subscription", "for": ["not-cancelling", "has-payment-method"], "optionKey": "cancel-subscription" },
                    reactivate: { "label": "reactivate subscription", "for": ["cancelling", "has-payment-method"], "optionKey": "reactivate-subscription-before-period-end" }
                },
                features: {
                    reports: { "label": "buy & sell reports", "permission": "all reports" },
                    filings: { "label": "company filings", "permission": "all data" },
                    form4Details: { "label": "form 4 details", "permission": "yes" },
                    cancel: { "label": "cancel anytime", "permission": "yes" }
                }
            },
            trial: {
                core: { "label": "pro trial", "defaultPrice": "0", "interval": "month", "defaultCurrency": "usd" },
                details: {
                    amount: { "label": "price", "data": "$1/$2" },
                    start: { "label": "start", "data": "$1" },
                    end: { "label": "end", "data": "$1" },
                    afterTrial: { "label": "after trial ends", "data": "$1" }
                },
                afterTrialText: {
                    invoice: "after the trial period ends, your credit card will be charged $1/$2 starting on $3. you can cancel anytime.",
                    cancel: "trial will automatically cancel at the end of the trial period. you can add a payment method to continue with a paid subscription."
                },
                options: {
                    addPaymentMethod: { "label": "add payment method", "for": ["cancelling", "no-payment-method"], "optionKey": "add-payment-method" },
                    reactivate: { "label": "reactivate subscription after trial", "for": ["cancelling", "has-payment-method"], "optionKey": "reactivate-subscription-before-period-end" },
                    changePaymentMethod: { "label": "change payment method", "for": ["not-cancelling", "has-payment-method"], "optionKey": "change-payment-method" },
                    cancel: { "label": "cancel pending subscription", "for": ["not-cancelling", "has-payment-method"], "optionKey": "cancel-subscription-after-trial" },
                }                
            }
        },
        tou: {
            agreement: "by checking the box you agree to our terms of use"
        },
        coupons: {
            checkNotice: "checking this coupon...",
            validNotice: "the coupon code is valid. click 'Next: Payment' to apply the discount.",
            invalidNotice: "this coupon code is invalid.",
            expiredNotice: "this coupon code is expired.",
            consumedNotice: "this coupon code has already been used."
        },        
        notices: {
            generalError: "sorry, something went wrong. please try again.",
            badCurrent: "your current password is not correct. please try again.",
            badMatch: "passwords don't match.",
            badEmail: "please enter a valid email address.",
            badPassword: "passwords must have at least 1 uppercase character and 1 number, and be between 8 and 16 characters.",
            badEmailAndPassword: "please enter a valid email address and a password with at least 1 uppercase character and 1 number that is between 8 and 16 characters.",
            badCardDetails: "please enter your complete card details",
            goodUpdatePymtMethod: "your new card details were successfully saved.",
            goodUpgrade: "your upgrade was successful.",
            goodSignup: "your signup was successful.",
            goodCancel: "your cancellation request was processed successfully.",
            goodReactivate: "your subscription reactivation was processed successfully.",
            emailExists: "this email already has an account associated with it."
        },
        login: {
            enterCredsPanel: {
                title: "login",
            }
        },
        signup: {
            createUserPanel: {
                title: "sign up for a new account",
                subTitle: "start for free with Basic or subscribe to Pro."
            },
            verifyEmailPanel: {
                title: "verify your email address",
                subTitle: "just one more step. we sent an email to your email address. open the email and click the link to verify your account."                
            },
            paymentDetailsPanel: {
                title: "checkout",
                subTitle: "amount to be charged:",
                defaultCurrency: "$",
                period: "/month"
            },
            paymentSuccessPanel: {
                title: "congratulations!",
                subTitle: "your subscription was processed successfully."
            },
            trialSuccessPanel: {
                title: "congratulations!",
                subTitle: "congratulations! your $1-day free trial has been activated. The free trial will end on $2."
            }
        },
        resetPassword: {
            newPasswordPanel: {
                title: "reset password",
            },
            successPanel: {
                title: "success",
                subTitle: "your password was successfully changed."
            }
        },
        forgotPassword: {
            enterEmailPanel: {
                title: "get a password reset email",
            },
            successPanel: {
                title: "reset password email sent",
                subTitle: "we sent a password reset email to your email address. click the link in the email to reset your password."
            }            
        },
        verifyEmail: {
            applyPanel: {
                title: "one moment",
                subTitle: "please wait while we verify your email address."
            },
            successPanel: {
                title: "success",
                subTitle: "your email address was verified."
            }
        },
        settings: {
            profile: {
                title: "profile",
                labels: {
                    username: "username",
                    password: "password"                    
                }
            },
            plan: {
                title: "plan"
            }
        },
        updatePayment: {
            cardDetailsPanel: {
                titleUpdate: "update payment method",
                titleAdd: "add payment method",
                titleUpgrade: "upgrade to pro",
                subTitle: "please enter your card information below."
            }
        }
    },
    modalContent: {
        buttons: {
            close: "close",
            home: "back to home"
        },
        confirmation: {
            title: "are you sure?",
            planCancel: "if you cancel, you will lose access to premium features including report data and research articles."
        },
        displayArticles: {
            verifyEmail: "please verify your email address to view articles.",
            login: "please login or signup to view articles."
        },
        settings: {
            login: "please login or signup to access settings."
        },
        form4: {
            title: "selected form 4 filing data",
            accNum: "accession number",
            box1: {
                name: "reporting person"
            },
            box2: {
                issuer: "issuer",
                ticker: "ticker"
            },
            box5: {
                relationship: "relationship",
                director: "director",
                officer: "officer",
                tenPctOwner: "10% owner",
                other: "other"
            },
            box6: {
                filingType: "filing type",
                individual: "individual",
                joint: "joint"
            },
            footnotes: "footnotes"
        },
        tou: {
            title: "terms of use"
        }
    },
    support: {
        questions: {
            "label": "Questions? Send us an email:",
            "email": "support@inveyo.com"
        }
    }
}

export default strings