import React, { useState } from "react";
import TransactionCodeButtonWrapper from "./transaction-code-button-wrapper";
import styles from "./advanced-search-area.module.css";

const IDS = {
    "area": { "id": "advancedSearchArea" },
    "shPrMin": { "id": "advancedSearchSharePriceMin" },
    "shPrMax": { "id": "advancedSearchSharePriceMax" },
    "lqMin": { "id": "advancedSearchLiquidityMin" },
    "lqMax": { "id": "advancedSearchLiquidityMax" },
    "fl": { "id": "advancedSearchFilingDate" },
    "sec": { "id": "advancedSearchSecurity" },
    "own": { "id": "advancedSearchOwner" },
    "name": { "id": "advancedSearchOwnerName" },
    "ind": { "id": "advancedSearchIndustry" }
}

const INITIALIZE_TCB = () => {
    return {
      "p": "unselected",
      "s": "unselected",
      "a": "unselected",
      "d": "unselected",
      "m": "unselected"
    }
};

const AdvancedSearchArea = ({ DEFAULT_SEARCH_PARAMETERS, searchIds, isAdvancedSearchVisible, searchParameters, setSearchParameters, parseUserInputAdvanced, allIndustries, strings }) => {
    const [selectedTCB, setSelectedTCB] = useState(INITIALIZE_TCB);
    const DEFAULT_SEARCH_INPUT_VALUE = strings.search.input.label; 
    const advancedSearchAreaStrings = strings.advancedSearch;

    const advancedSearchCheckNumericalValues = (value) => {
        /**
         * The `advancedSearchCheckNumericalValues` method accepts a form value which should be a 
         * number and tests if the value is `NaN`. If the value is `NaN`, then the value is set to 0.
         * 
         * @param {any} `value`: The value for checking.
         * @return {int, float}: The original value if a number, else `0`.
         * @throw Set `value` to 0.
         */
        try {
            if (isNaN(value)) {
                value = 0;
            }
        } catch {
            // If an error occurs, set the value to 0.
            value = 0;
        }

        return value
    }

    const advancedSearchTCBClickHandler = (e) => {
        e.stopPropagation();
    
        // Get the index of the transaction type button that was clicked using 'data-ttb-key' attribute
        let _code = e.target.attributes.getNamedItem("data-tcb-key").value;
        
        // Make a copy of the current transaction type buttons state
        let _transactionButtonState = { ...selectedTCB }
    
        // Modify the copy of the state by adding/removing the code for the clicked button
        if ("unselected" === _transactionButtonState[_code]) {
          _transactionButtonState[_code] = "selected";
        } else {
          _transactionButtonState[_code] = "unselected";
        }
    
        // Set the new state using the React hook
        setSelectedTCB(_transactionButtonState);
    }

    const advancedSearchSubmitClickHandler = (e) => {
        e.stopPropagation();
        
        // Initialize `_switchIntent` flag.
        let _switchIntent = false;
    
        // Get the form intent element value
        let _searchInputValue = document.getElementById(searchIds.input.id).value;
    
        // Get the user intent and sanitized input
        let _intent;
        let _sanitizedInput;
        [_intent, _sanitizedInput] = parseUserInputAdvanced(_searchInputValue);
    
        // If the user is switching intent from the current intent, set `switchIntent` flag to `true`.
        if (("entity" === _intent) || ("report" === _intent)) {
          if ((("entity" === _intent) && ("report" === searchParameters["intent"])) || (("report" === _intent) && ("entity" === searchParameters["intent"]))) {
            _switchIntent = true;
          }
        } else {
          _intent = searchParameters["intent"];
        }
    
        // Create a copy of search parameters
        let _searchParametersCopy = { ...searchParameters };    
    
        /* Set search parameters */
        // Get common form element values
        let _formSharePriceMin = document.getElementById(IDS.shPrMin.id).value;
        let _formSharePriceMax = document.getElementById(IDS.shPrMax.id).value;
        let _formLiquidityMin = document.getElementById(IDS.lqMin.id).value;
        let _formLiquidityMax = document.getElementById(IDS.lqMax.id).value;
        let _formOwner = document.getElementById(IDS.own.id).value;
        let _formOwnerName = document.getElementById(IDS.name.id).value;    
    
        // Parse share price and liquidity values
        _formSharePriceMin = parseFloat(_formSharePriceMin);
        _formSharePriceMax = parseFloat(_formSharePriceMax);
        _formLiquidityMin = parseInt(_formLiquidityMin);
        _formLiquidityMax = parseInt(_formLiquidityMax);

        // Check for NaN values and coerce any to 0
        _formSharePriceMin = advancedSearchCheckNumericalValues(_formSharePriceMin);
        _formSharePriceMax = advancedSearchCheckNumericalValues(_formSharePriceMax);
        _formLiquidityMin = advancedSearchCheckNumericalValues(_formLiquidityMin);
        _formLiquidityMax = advancedSearchCheckNumericalValues(_formLiquidityMax);

        // DEBUG
        // console.group();
        // console.log(_formSharePriceMin);
        // console.log(_formSharePriceMax);
        // console.groupEnd();
        // DEBUG

        // Set parameters based on intent
        switch (_intent) {
          case ("entity"):
            // Get entity-specific form element values    
            let _formFilingDate = document.getElementById(IDS.fl.id).value;
            let _formSecurity = document.getElementById(IDS.sec.id).value;
    
            // Set dictionary with advanced search parameters
            _searchParametersCopy = {
              "intent": _intent,
              "entity": (_sanitizedInput === "" || _sanitizedInput === DEFAULT_SEARCH_INPUT_VALUE) ? searchParameters["entity"] : _sanitizedInput,
              "report": null,
              "sharePriceMin": _formSharePriceMin === "" ? 0 : _formSharePriceMin,
              "sharePriceMax": _formSharePriceMax === "" ? 0 : _formSharePriceMax,
              "liquidityMin": _formLiquidityMin === "" ? 0 : _formLiquidityMin,
              "liquidityMax": _formLiquidityMax === "" ? 0 : _formLiquidityMax,
              "industry": null,
              "filingDate": _switchIntent === true ? null : _formFilingDate,
              "security": _switchIntent === true ? null : _formSecurity,
              "owner": _formOwner,
              "ownerName": _formOwnerName,
              "selectedTCB": selectedTCB
            }
            break;
          case ("report"):
            // Get report-specific form element values            
            let _industry = document.getElementById(IDS.ind.id).value;
            
            // Set dictionary with advanced search parameters
            _searchParametersCopy = {
              "intent": _intent,
              "entity": null,
              "report": (_sanitizedInput === "" || _sanitizedInput === DEFAULT_SEARCH_INPUT_VALUE) ? searchParameters["report"] : _sanitizedInput,
              "sharePriceMin": _formSharePriceMin === "" ? 0 : _formSharePriceMin,
              "sharePriceMax": _formSharePriceMax === "" ? 0 : _formSharePriceMax,
              "liquidityMin": _formLiquidityMin === "" ? 0 : _formLiquidityMin,
              "liquidityMax": _formLiquidityMax === "" ? 0 : _formLiquidityMax,
              "industry": _switchIntent === true ? null : _industry,
              "filingDate": null,
              "security": null,
              "owner": _formOwner,
              "ownerName": _formOwnerName,
              "selectedTCB": INITIALIZE_TCB()
            }
            break;
          default:
            // pass
        }

        // DEBUG
        // console.log(_searchParametersCopy);
        // DEBUG

        // Set the new state using the React hook
        setSearchParameters(_searchParametersCopy);
    }

    const advancedSearchResetClickHandler = (e) => {
        // Grab common form elements  
        let _formSharePriceMin = document.getElementById(IDS.shPrMin.id);
        let _formSharePriceMax = document.getElementById(IDS.shPrMax.id);
        let _formLiquidityMin = document.getElementById(IDS.lqMin.id);
        let _formLiquidityMax = document.getElementById(IDS.lqMax.id);
        let _formOwner = document.getElementById(IDS.own.id);
        let _formOwnerName = document.getElementById(IDS.name.id); 
    
        // Reset each form element.
        _formSharePriceMin.value = "";
        _formSharePriceMax.value = "";
        _formLiquidityMin.value = "";
        _formLiquidityMax.value = "";
        _formOwner.options[0].selected = true;
        _formOwnerName.value = "";
    
        // Get current intent
        let _intent = searchParameters["intent"];
    
        // Get intent specific elements and reset them
        switch (_intent) {
          case ("entity"):
            // Get entity-specific form element values
            let _formFilingDate = document.getElementById(IDS.fl.id);
            let _formSecurity = document.getElementById(IDS.sec.id);
    
            _formFilingDate.options[0].selected = true;
            _formSecurity.options[0].selected = true;
    
            setSelectedTCB(INITIALIZE_TCB)
            break;
          case ("report"):
            // Get report-specific form element values
            let _industry = document.getElementById(IDS.ind.id);
    
            _industry.options[0].selected = true;
            break;
          default:
            // pass
        }
    
        resetSearchParameters();
      }

      const resetSearchParameters = () => {
        let _searchParameters = { ...DEFAULT_SEARCH_PARAMETERS };
        let _intent = searchParameters["intent"];
        let _entity = searchParameters["entity"];
        let _report = searchParameters["report"];
    
        _searchParameters["intent"] = _intent;
        _searchParameters["entity"] = _entity;
        _searchParameters["report"] = _report;
    
        setSearchParameters(_searchParameters);
    
        return
    }

    if ((1 === isAdvancedSearchVisible) && ("entity" === searchParameters["intent"])) {
        return(
            <div id = { IDS.area.id } className = { [styles.advancedSearchAreaContainer, "container-fluid"].join(" ") }>
                <div className = "row justify-content-start align-items-center">
                    <div className = "col-md-3">
                        <div className = "row justify-content-center align-items-center">
                            <div className = "col-6 mt-3 mb-3">
                                <label htmlFor = { IDS.shPrMin.id }>{ advancedSearchAreaStrings.sharePriceMin["label"] }</label>
                                <input id = { IDS.shPrMin.id } className = "form-control mt-2" type = "number" placeholder = { advancedSearchAreaStrings.sharePriceMin["placeholder"] }></input>
                            </div>
                            <div className = "col-6 mt-3 mb-3">    
                                <label htmlFor = { IDS.shPrMax.id }>{ advancedSearchAreaStrings.sharePriceMax["label"] }</label>
                                <input id = { IDS.shPrMax.id } className = "form-control mt-2" type = "number" placeholder = { advancedSearchAreaStrings.sharePriceMax["placeholder"] }></input>
                            </div>
                        </div>
                    </div>
                    <div className = "col-md-3">
                        <div className = "row justify-content-center align-items-center">
                            <div className = "col-6 mt-3 mb-3">
                                <label htmlFor = { IDS.lqMin.id }>{ advancedSearchAreaStrings.liquidityMin["label"] }</label>
                                <input id = { IDS.lqMin.id } className = "form-control mt-2" type = "number" placeholder = { advancedSearchAreaStrings.liquidityMin["placeholder"] }></input>
                            </div>
                            <div className = "col-6 mt-3 mb-3">    
                                <label htmlFor = { IDS.lqMax.id }>{ advancedSearchAreaStrings.liquidityMax["label"] }</label>
                                <input id = { IDS.lqMax.id } className = "form-control mt-2" type = "number" placeholder = { advancedSearchAreaStrings.liquidityMax["placeholder"] }></input>
                            </div>
                        </div>
                    </div>
                    <div className = "col-md-3">
                        <div className = "row justify-content-center align-items-center">
                            <div className = "col-12 mt-3 mb-3">
                                <label htmlFor = { IDS.fl.id }>{ advancedSearchAreaStrings.filingDate["label"] }</label>
                                <select id = { IDS.fl.id } className = "form-select mt-2">
                                    {
                                        Object.keys(strings.filingDateOptions).map((option, index) => <option key = { `filing-date-option-${index}` }>{ option }</option>)
                                    }
                                </select>                            
                            </div>
                        </div>  
                    </div>
                    <div className = "col-md-3">
                        <div className = "row justify-content-center align-items-center">
                            <div className = "col-12 mt-3 mb-3">
                                <label htmlFor = { IDS.sec.id }>{ advancedSearchAreaStrings.security["label"] }</label>
                                <select id = { IDS.sec.id } className = "form-select mt-2">
                                    {
                                        Object.keys(strings.securityOptions).map((option, index) => <option key = { `security-option-${index}` }>{ option }</option>)
                                    }
                                </select>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className = "row justify-content-start align-items-center">
                    <div className = "col-md-3">
                        <div className = "row justify-content-center align-items-center">
                            <div className = "col-12 mt-3 mb-3">
                                <label htmlFor = { IDS.own.id }>{ advancedSearchAreaStrings.owner["label"] }</label>
                                <select id = { IDS.own.id } type = "text" className = "form-select mt-2">
                                    {
                                        Object.keys(strings.ownerOptions).map((option, index) => <option key = { `owner-option-${index}` }>{ option }</option>)
                                    }
                                </select>                                
                            </div>
                        </div>
                    </div>
                    <div className = "col-md-3">
                        <div className = "row justify-content-center align-items-center">
                            <div className = "col-12 mt-3 mb-3">
                                <label htmlFor = { IDS.name.id }>{ advancedSearchAreaStrings.ownerName["label"] }</label>
                                <input id = { IDS.name.id } className = "form-control mt-2" type = "text" placeholder = { advancedSearchAreaStrings.ownerName["placeholder"] }></input>                            
                            </div>
                        </div>   
                    </div>
                    <div className = "col-md-3">
                        <div className = "row justify-content-center align-items-center">
                            <div className = "col-12 mt-3 mb-3">
                                <div className = "row justify-content-evenly align-items-center">
                                    {
                                        strings.advancedSearch.transactionCodeButtons.map((obj, index) => (TransactionCodeButtonWrapper(obj, index, advancedSearchTCBClickHandler, selectedTCB)))
                                    }
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div className = "col-md-3">
                        <div className = "row justify-content-start align-items-center">
                            <div className = "col-md-12 mt-3 mb-3">
                                <button className = { ["btn", "btn-primary", styles.buttonMTAdj, styles.buttonWidth].join(" ") } onClick = { (e) => advancedSearchSubmitClickHandler(e) }>Submit</button>
                                <button className = { ["btn", "btn-warning", "ms-2", styles.buttonMTAdj, styles.buttonWidth].join(" ") } onClick = { (e) => advancedSearchResetClickHandler(e) }>Reset</button>
                            </div>                            
                        </div>
                    </div>                    
                </div>                 
            </div>
        )
    } else if ((1 === isAdvancedSearchVisible) && ("report" === searchParameters["intent"])) {
        return(
            <div id = { IDS.area.id } className = { [styles.advancedSearchAreaContainer, "container-fluid"].join(" ") }>
                <div className = "row justify-content-start align-items-center">
                    <div className = "col-md-3">
                        <div className = "row justify-content-center align-items-center">
                            <div className = "col-6 mt-3 mb-3">
                                <label htmlFor = { IDS.shPrMin.id }>{ advancedSearchAreaStrings.sharePriceMin["label"] }</label>
                                <input id = { IDS.shPrMin.id } className = "form-control mt-2" type = "number" placeholder = { advancedSearchAreaStrings.sharePriceMin["placeholder"] }></input>
                            </div>
                            <div className = "col-6 mt-3 mb-3">    
                                <label htmlFor = { IDS.shPrMax.id }>{ advancedSearchAreaStrings.sharePriceMax["label"] }</label>
                                <input id = { IDS.shPrMax.id } className = "form-control mt-2" type = "number" placeholder = { advancedSearchAreaStrings.sharePriceMax["placeholder"] }></input>
                            </div>
                        </div>
                    </div>
                    <div className = "col-md-3">
                        <div className = "row justify-content-center align-items-center">
                            <div className = "col-6 mt-3 mb-3">
                                <label htmlFor = { IDS.lqMin.id }>{ advancedSearchAreaStrings.liquidityMin["label"] }</label>
                                <input id = { IDS.lqMin.id } className = "form-control mt-2" type = "number" placeholder = { advancedSearchAreaStrings.liquidityMin["placeholder"] }></input>
                            </div>
                            <div className = "col-6 mt-3 mb-3">    
                                <label htmlFor = { IDS.lqMax.id }>{ advancedSearchAreaStrings.liquidityMax["label"] }</label>
                                <input id = { IDS.lqMax.id } className = "form-control mt-2" type = "number" placeholder = { advancedSearchAreaStrings.liquidityMax["placeholder"] }></input>
                            </div>
                        </div>
                    </div>
                    <div className = "col-md-3">
                        <div className = "row justify-content-center align-items-center">
                            <div className = "col-12 mt-3 mb-3">
                                <label htmlFor = { IDS.own.id }>{ advancedSearchAreaStrings.owner["label"] }</label>
                                <select id = { IDS.own.id } type = "text" className = "form-select mt-2">
                                    {
                                        Object.keys(strings.ownerOptions).map((option, index) => <option key = { `owner-option-${index}` }>{ option }</option>)
                                    }
                                </select>                                
                            </div>
                        </div>
                    </div>
                    <div className = "col-md-3">
                        <div className = "row justify-content-center align-items-center">
                            <div className = "col-12 mt-3 mb-3">
                                <label htmlFor = { IDS.name.id }>{ advancedSearchAreaStrings.ownerName["label"] }</label>
                                <input id = { IDS.name.id } className = "form-control mt-2" type = "text" placeholder = { advancedSearchAreaStrings.ownerName["placeholder"] }></input>                            
                            </div>
                        </div>   
                    </div>                    
                </div>
                <div className = "row justify-content-start align-items-center">
                    <div className = "col-md-3">
                        <div className = "row justify-content-center align-items-center">
                            <div className = "col-12 mt-3 mb-3">
                                <label htmlFor = { IDS.ind.id }>{ strings.advancedSearch.industry["label"] }</label>
                                <select id = { IDS.ind.id } type = "text" className = "form-select mt-2">
                                    {
                                        allIndustries.current.map((industry, index) => <option key = { `industry-option-${index}` }>{ industry }</option>)
                                    }
                                </select>                             
                            </div>
                        </div>
                    </div>
                    <div className = "col-md-3">
                        <div className = "row justify-content-start align-items-center">
                            <div className = "col-md-12">
                                <button className = { ["btn", "btn-primary", styles.buttonMTAdj, styles.buttonWidth].join(" ") } onClick = { (e) => advancedSearchSubmitClickHandler(e) }>Submit</button>
                                <button className = { ["btn", "btn-warning", "ms-2", styles.buttonMTAdj, styles.buttonWidth].join(" ") } onClick = { (e) => advancedSearchResetClickHandler(e) }>Reset</button>
                            </div>                            
                        </div>
                    </div>                    
                </div>
            </div>
        )
    } else {
        return(
            <React.Fragment></React.Fragment>
        )
    }
}

export default AdvancedSearchArea